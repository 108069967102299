@import "../../style/mixins";

.pp-section-tc {
  padding: 48px 0;

  &.grey {
    background: #EBEDF3;
  }
}

@include break-point(mobile) {
  .pp-section-tc {
    padding: 48px 0 32px !important;

    &.performance {
      .swiper-slide {
        min-height: 360px;
      }
    }

    &.comfort {
      .swiper-slide {
        min-height: 315px;
      }
    }
  }
}