@import "../../style/mixins";

.pp-section-regions {
  width: 100%;
  height: 100vh;

  .box {
    width: 100%;
    max-width: 1090px;
    padding: 0 60px;
    @include positionCC;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 200px;
      height: auto;
      margin-bottom: 40px;
    }

    .title-block {

      .title {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
      }
    }

    .regions {
      display: flex;
      justify-content: space-around;
      margin-top: 60px;
      width: 85%;
      max-width: 700px;
    }
  }
}

@include break-point(mobile) {

  .pp-section-regions {

    .box {
      padding: 0 16px;

      .regions {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;

        .region {
          margin: 0 12px 30px;
        }
      }
    }
  }

}
