@import "../../style/mixins";

.pp-section-interior {
  padding-top: 144px;

  &.blue {
    background: #002C5E;
  }

  .swiper-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .interior-block {
      margin-top: 48px;
      width: calc(50% - 20px);

      &:nth-child(1n + 2) {
        margin-top: 96px;
      }

      .image {
        height: auto;
      }

      .title {
        margin-top: 40px;
        @include title-h3;
        color: #fff;
      }

      .subtitle {
        margin-top: 12px;
        @include text-simple;
        color: #fff;
      }
    }
  }

  .swiper-pagination {
    margin-top: 20px;
    display: none;

    &-bullet {
      border: 1px solid #fff;

      &-active {
        background: #fff;
      }
    }
  }

}


@include break-point(mobile) {
  .pp-section-interior {

    &.rtl {
      .title-block {
        text-align: right;
      }
    }

    &.ltr {
      .title-block {
        text-align: left;
      }
    }

    .swiper-container {
      margin-top: 40px;
      display: flex;
      flex-direction: column-reverse;
    }

    .swiper-slide {
      min-height: 410px;
      .counter {
        margin-top: 24px;
      }

      .title {
        margin-top: 16px;
        @include title-h3;
        color: #fff;
      }

      .subtitle {
        margin-top: 12px;
        @include text-simple;
        color: #fff;
      }
    }

    .swiper-pagination {
      margin-top: 20px;
      display: block;
    }
  }
}
