@import "../../../../style/mixins";

.simple-slide {

  .info-block {
    .text-block {
      margin-top: 16px;
    }
  }
  &.half {
    width: 50%;
  }
}
