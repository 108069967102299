@import "../../style/mixins";

.block-tiles {
  margin-top: 42px;

  &.white {
    background: #fff;
  }

  &.rtl {
    .swiper-container {
      .tile {
        margin-left: 24px;

        &:nth-child(3n + 2) {
          margin-left: 0;
        }
      }
    }
  }

  &.ltr {
    .swiper-container {
      .tile {
        margin-right: 24px;

        &:nth-child(3n + 2) {
          margin-right: 0;
        }
      }
    }
  }

  .title-block {
    text-transform: uppercase;
  }

  .swiper-container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .tile {
      margin-top: 42px;
      width: calc(33% - 24px);

      .image {
        height: auto;
      }

      .title {
        margin-top: 40px;
        @include text-simple;
        font-weight: 600;
      }

      .subtitle {
        margin-top: 12px;
        @include text-simple;
      }
    }
  }

  .swiper-pagination {
    margin-top: 20px;
    display: none;
    bottom: 0;

    &-bullet {
      border: 1px solid #002C5E;

      &-active {
        background: #002C5E;
      }
    }
  }

}

@include break-point(mobile) {

  .block-tiles {
    margin-top: 0;
    .title-block {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .swiper-container {
      display: flex;
      justify-content:  space-between;
      flex-direction: column-reverse;

    }

    .swiper-slide {
      min-height: 280px;
      padding-bottom: 24px;
      background: #EBEDF3;

      &.white {
        background: #fff;
      }
      .counter {
        margin-top: 24px;
      }

      .title {
        padding: 0 16px;
        margin-top: 16px;
        @include title-h3;
      }

      .subtitle {
        padding: 0 16px;
        margin-top: 12px;
        @include text-simple;
      }
    }

    .swiper-pagination {
      display: block;
    }

  }
}