@import "../../style/mixins";

.pp-section-gallery {
  background-color: #000000;

  .title-block {
    margin-bottom: 48px;
  }

  .slider {
    .wrapper {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}

@include break-point(mobile) {
  .pp-section-gallery {

    .slider-thumb {
      display: none;
    }

    .slider {

      .slider-container {

        .swiper-wrapper {
          overflow: hidden;
        }
      }

    }
  }
}

