@import "./src/style/mixins";

.pp-section-thankyou {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    max-width: 380px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 250px;
    height: auto;
    margin-bottom: 24px;
  }
  h1 {
    @include HyundaiMedium(32px, 1.25, normal, 600, #000);
    display: block;
    margin-bottom: 8px;
  }
  h2 {
    @include HyundaiRegular(18px, 1.25, normal, normal, #000);
    max-width: 380px;
  }
  .timer {
    @include HyundaiMedium(48px, 1, normal, 600, #003469);
    padding: 24px 0;
  }
  a {
    cursor: pointer;
    @include HyundaiRegular(18px, 1.25, normal, normal, #8a8a8a);
    transition: 0.16s;
    text-decoration: none;
    &:hover {
      color: #00aad2;
    }
  }
}