@import "./src/style/mixins";

.rtl {
  .parameters-slider {
    .parameter-slide {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}

.ltr {
  .parameters-slider {
    .parameter-slide {
      &:last-of-type {
        margin-right: 16px;
      }
    }
  }
}

.parameters-slider {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 24px;
  border-bottom: 1px solid #F6F3F2;
  .pagination {
    display: none;
  }
  .swiper-wrapper {
    padding: 8px 0 0;
  }
  /*.parameter-slide {
    &:last-of-type {
      margin-right: 16px;
    }
  }*/
  .controls {
    display: none;
  }
  &:last-of-type {
    padding-bottom: 0;
    border: none;
  }
  .content {
    width: calc(100% - 231px);
  }
  .info {
    padding-top: 8px;
    min-width: 270px;
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .title, .subtitle {
      width: 100%;
      display: block;
      @include text-subtitle;
    }
    .title {
      margin-bottom: 4px;
      font-weight: 600;
    }
  }
}

@include break-point(tablet){
  .parameters-slider {
    .info {
      min-width: 150px;
    }
    .content {
      width: calc(100% - 174px);
    }
  }
}
@include break-point(mobile){
  .parameters-slider {
    .controls {
      display: block;
    }
    flex-direction: column;
    .info {
      flex-direction: row;
      justify-content: space-between;
      margin-right: 0;
      width: 100%;
      .title, .subtitle {
        width: auto;
      }
    }
    .content {
      width: 100%;
    }
  }
}

