@import "../../style/mixins";

.pp-popup {
  //transform: translateX(16px);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100vw;
  display: none;
  opacity: 0;
  transition: 0.4s transform, 0.4s opacity;
  z-index: 1000;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .box {
    z-index: 5;
  }

  .close {
    z-index: 5;
    cursor: pointer;
    width: 72px;
    height: 72px;
  }

  &.open {
    opacity: 1;
  }

  &-modal {

    .close {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      background-color: transparent;
      transition: 0.26s;

      svg {
        @include positionCC;
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: $dark-color;
      }
    }

    .overlay {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .box {
      background-color: var(--primary-color);
      @include positionCC;
      transform: translateX(-50%) translateY(-45%);
      transition: 0.5s transform;
    }

    &.open {
      opacity: 1;
      .box {
        transform: translate(-50%, -50%);
      }
    }
  }

  &-youtube {

    .close {
      z-index: 10;
    }

    .box {
      height: 606px;
      width: 1078px;
      overflow: hidden;

      .youtube {
        width: 100%;
        height: 100%;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.black {
    .box {
      background-color: #000;
    }
  }
}

@include break-point(desktop-small) {
  .pp-popup {
    &-youtube {
      .box {
        height: 580px;
        width: 1032px;
      }
    }
  }
}

@include break-point(mobile) {
  .pp-popup {
    &-youtube {
      .close {
        background-color: $dark-color;
        width: 60px;
        height: 60px;
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .box {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }
}
