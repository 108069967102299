@import "swiper";
@import "./../../style/mixins";

.slider {

  &.visible {
    .swiper-container {
      overflow: visible;
    }
  }

  &.slider-galley {
    .controls {
      top: 50%;
    }
  }

  &.slider-thumb {

    .swiper-slide {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.6);
        transition: 0.2s;
        cursor: pointer;
      }

      &:hover {
        &:after {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }

      &.active {
        &:after {
          background-color: rgba(0, 0, 0, 0);
          cursor: default;
        }
      }
    }
  }

  &[dir='rtl'] {
    .controls {
      transform: rotate(180deg);
      flex-direction: row-reverse;
      &.duo {
        top: -48px;
        right: unset;
        left: 0;
        transform: translateY(-100%) rotate(180deg);
        width: auto;

        .control-arrow {
          transform: none;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }

          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
    .control-arrow.single {
      left: 0;
      right: auto;
      transform: translateX(-50%) translateY(-50%) rotate(180deg);
      &.reversed {
        @include positionY;
        transform: translateX(50%) translateY(-50%) rotate(180deg);
        right: 0;
      }
    }
    .color-button-next.single {
      left: -24px;
    }
    .color-button-prev.single.reversed {
      top: 30%;
      right: -24px;
    }
  }
}

@include break-point(mobile) {
  .slider-container {
    padding: 0;

    .slider-content {

      .swiper-container {
        padding: 0 16px;
      }
    }
  }
}
