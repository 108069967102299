@import "./src/style/mixins";

.panorama-configurator {
  .main-content {
    height: 400px;
    .pnlm-container {
      background: #fff;
    }
    .pnlm-load-box {
      @include positionCC;
      width: 100px;
      height: 100px;
      margin: 0;
      background-color: rgba(0,0,0,0.7);
      border-radius: 50px;
      text-align: center;
      font-size: 20px;
      .pnlm-lbar {
        width: 65%;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      p {
        display: none;
        margin: 0;
      }
      color: #fff;
      display: none;
    }
    .pnlm-load-button {
      p {
        display: none;
      }
      width: 100px;
      height: 100px;
      border-radius: 50px;
      margin: 0;
      @include positionCC;
      &:after {
        content:"";
        @include positionCC;
        width: 100px;
        height: 100px;
        margin: auto;
        background: url(https://scaleflex.ultrafast.io/https://scaleflex.airstore.io/filerobot/js-cloudimage-360-view/360_view.svg) 50% 50% / contain no-repeat rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        box-shadow: rgba(255, 255, 255, 0.5) 0px 0px 4px;
        transition: all 0.5s ease 0s;
        color: rgb(80, 80, 80);
        text-align: center;
        line-height: 100px;
      }
    }
  }
}


@include break-point(mobile){
  .panorama-configurator {
    .main-content {
      height: 220px;
    }
  }
}