@import "../../style/mixins";

.pp-section-good {

  .title-block {
    min-height: 250px;
  }

  .slider {
    margin-top: 48px;
  }

  &.centered {

  }
}

@include break-point(mobile) {
  .pp-section-good {
    .title-block {
      min-height: unset;
    }
    .slider {
      margin-top: 0;
    }
    .container {
      display: flex;
      flex-direction: column-reverse;
    }

    .title-block {
      margin-top: 48px;
      //min-height: 290px;
      min-height: unset;
    }
  }
}
