@import "../../style/mixins";

.fs-banner {
  margin-top: 120px;
  background: #fff;

  .cover {
    .dark-cover {
      z-index: 49;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #000, rgba(0,0,0,0));
      opacity: 0.3;
    }

    .label {
      position: absolute;
      top: 45px;
      left: 45px;
      @include title-h1;
      color: #fff;
      z-index: 50;
      text-transform: uppercase;
    }
  }

  .title {
    width: 100%;
    padding: 40px;
    @include title-h3;
  }
}

@include break-point(mobile) {
  .fs-banner {
    .cover {
      .label {

      }
    }
  }
}