@import "./src/style/mixins";

.pp-section-disclaimers {
  p {
    text-align: center;
    @include text-small;
    font-size: 12px;
    color: #666;
    display: block;
    padding-bottom: 8px;
  }
}