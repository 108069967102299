@import "../../style/mixins";

.toggle {
  display: flex;
  justify-content: center;

  ul {
    background-color: #ffffff;
    padding: 4px;
    margin: 0;
    border-radius: 24px;
    display: flex;
  }

  li {
    @include text-simple;
    padding: 9px 32px;
    border-radius: 24px;
    transition: 0.28s;
    cursor: pointer;
    margin-right: 8px;

    &:last-child {
      margin: 0;
    }

    &:not(.active):hover {
      background-color: $gray-color;
    }

    &.active {
      background-color: $primary-color;
      color: #ffffff;
      cursor: default;
    }
  }

  &[dir="rtl"]{
    li {
      margin-right: 0;
      margin-left: 8px;
      &:last-child {
        margin: 0;
      }
    }
  }
}


@include break-point(mobile) {

  .toggle {
    justify-content: flex-start;
    overflow-x: scroll;

    ul {
      background-color: transparent;
      overflow-x: scroll;
      justify-content: center;
      border-radius: 0;
      padding: 0 16px;
      flex: 1 0 auto;
    }

    li {
      padding: 12px 24px;
      background-color: #ffffff;
    }
  }

}
