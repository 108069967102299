@import "../../style/mixins";

.pp-section {

  &.play {
    padding-bottom: 0;
    background-color: #ebedf3;

    .container {
      text-align: center;

      .title-block {
        margin: 24px auto 48px;
      }

      .wrapper_youtube {
      }

      .youtube {
        margin-bottom: 48px;
        width: 100%;
        height: 672px;
      }

      .btns {
        .btn {
          min-width: 218px;
        }
      }

      .image-block {
        padding-top: 96px;
        max-height: 1296px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-between;
        overflow: hidden;

        .image {
          width: 32%;
          background: #00AAD2;
          margin: 0 auto;
          margin-bottom: 1em;
        }
      }
    }

    .step {
      display: flex;
      justify-content: space-between;

      &.rtl > .step__item:not(:last-of-type) {
        margin-left: 16px;
      }

      &.ltr > .step__item:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }
}

@include break-point(tablet) {
  .pp-section {
    &.play {
      .container {

        .youtube {
          height: 400px;
        }

        .step {
          flex-direction: column;
          align-items: center;

          &.rtl > .step__item:not(:last-of-type) {
            margin-left: 0;
          }

          &.ltr > .step__item:not(:last-of-type) {
            margin-right: 0;
          }
        }

        .image-block {
          justify-content: space-around;

          .image {
            width: 48%;
          }
        }
      }
    }
  }
}

@include break-point(mobile) {
  .pp-section {
    &.play {
      .container {

        .youtube {
          height: 250px;
        }
      }
    }
  }
}
