.image {
  width: 100%;
  height: 100%;
  .lazy-load-image-background {
    display: block !important;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
