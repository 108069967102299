@import "../../style/mixins";

.pp-section-hero {
  margin-top: 70px;
  width: 100%;
  height: 630px;

  .container {
    @include positionX;
    top: 48px;
    display: flex;
  }

  .title-block {
    text-align: left;
    //direction: ltr;
    z-index: 10;
    //width: 100%;
    max-width: 1140px;
    margin-right: auto;

    .subtitle {
      font-size: 32px;
    }
  }

  .image {
    &:after {
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.2);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
    img, picture {
      height: 100%;
    }
  }

  &[dir="rtl"] {

    .title-block {
      text-align: right;

      .btn-icon {
        margin-left: auto;
      }
    }
  }
}

@include break-point(mobile) {
  .pp-section-hero {
    height: 430px;
    margin-top: 60px;

    .container {
      top: 32px;
      transform: none;
      left: 0;
    }

    .title-block {

      .subtitle {
        font-size: 26px;
      }
    }

    &[dir="rtl"] {
      .title-block {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}
