@import "./src/style/mixins";

.configurator-360 {
  .main-content {
    height: 400px;
  }
  .placeholder {
    width: 100%;
  }
}

@include break-point(mobile){
  .configurator-360 {
    .main-content {
      height: 220px;
    }
  }
}