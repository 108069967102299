@import "../../style/mixins";

.pp-section-mf {
  background-color: #EBEDF3;
}


@include break-point(mobile) {
  .pp-section-mf {
    .pp-section.safe-care {
      padding-bottom: 36px;
    }

    .block-tiles {
      .swiper-slide.height {
        min-height: 420px;
      }
    }
  }
}
