@import "./src/style/mixins";

.pp-popup-menu {
  .box {
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    background: rgba(243,243,243,0.85);
    transform: translateX(-50%) translateY(-50%);

    .menu-mobile {
      padding: 20px;
      margin-top: 60px;
      display: flex;
      flex-direction: column;

      .menu-item {
        margin-bottom: 10px;
        @include text-subtitle;
        transition: .2s;

        &.active {
          color: #00AAD2;
          font-size: 24px;
        }
      }
    }
  }
  .close-text {
    position: absolute;
    bottom: 32px;
    left: 16px;
    right: 16px;
    text-align: center;
    background: #fff;
    border-radius: 100px;
    padding: 13px 0;
    font-size: 14px;
  }
}