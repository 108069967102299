@import "../../../style/mixins";

.pagination {

  .swiper-pagination {
    margin: 0 auto;
  }

  .swiper-pagination-progressbar {
    --swiper-pagination-color: $dark-color;
    background: $gray-color;
    height: 4px;
    width: 100%;
    position: relative;
    margin-top: 16px;
    border-radius: 2px;
    overflow: hidden;

    .swiper-pagination-progressbar-fill {
      background-color: $dark-color;
    }
  }
  &.dynamic {
    .pagination-container {
      display: flex;
      justify-content: center;
    }
  }

  .swiper-pagination.dynamic {
    left: -24px;
  }
  .swiper-pagination-bullets {
    //min-width: 100%;
    display: inline-flex;
    justify-content: center;
  }

  .swiper-pagination-bullet {
    margin: 74px 12px 0;
    transition: 0.2s;
    width: 8px;
    height: 8px;
    border: 1px solid $dark-color;
    display: inline-flex;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      border: 2px solid $dark-color;
      transition: 0.2s;
      opacity: 0;
      position: absolute;
      top: -1px;
      left: -1px;
      border-radius: 50%;
      box-sizing: border-box;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }

    &.active {
      opacity: 1;
      background-color: $dark-color;
    }
  }

  &.white {

    .swiper-pagination-bullet {
      border-color: #ffffff;

      &:after {
        border-color: #ffffff;
      }

      &.active {
        opacity: 1;
        background-color: #ffffff;
      }
    }
  }

  &.rtl {
    .swiper-pagination.dynamic {
      left: unset;
      right: -24px;
    }
  }
}



@include break-point(mobile) {
  .pagination {
    .pagination-container {
      margin: 0 auto;
      //width: calc(32px * 6);
      width: 100%;
      overflow: hidden;
    }

    .swiper-pagination {

      //width: calc(32px * 6) !important;
      display: flex;
      margin: 50px auto 0;
      //padding-left: 64px;
      min-width: unset;
      overflow: hidden;
      align-items: center;
      justify-content: center;

      &.dynamic {
        justify-content: unset;
        left: -24px;
        .swiper-pagination-bullet {
          &:not(.active-main) {
            opacity: 0;
          }

          &.active-next{
            width: 6px;
            height: 6px;
            margin: 0 9px;
            opacity: 1;
          }

          &.active-prev, &.active-prev-prev {
            opacity: 0;
          }

          &.active-next-next {
            width: 4px;
            height: 4px;
            opacity: 0.3;
            margin: 0 10px;
          }
        }
      }
    }

    .swiper-pagination-bullet {
      flex-shrink: 0;
      margin: 0 8px;
      transform: none !important;
    }
  }
}

@include break-point(tablet){
  .pagination {
    .swiper-pagination {
      &.dynamic {
        left: -12px;
        justify-content: center;
      }
    }
  }
}