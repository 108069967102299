@import "../../style/mixins";

.region {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .image {
    width: 20px;
    height: 20px;
    margin-bottom: 20px;
  }

  p {
    @include text-simple;
  }
}
