.pp-section-features {
  background-color: #EBEDF3;
  &.blue {
    background-color: #EBEDF3;
    .title-block .title {
      /*color: #fff;*/
    }
    .simple-slide {
      .title, .subtitle {
        color: #fff;
      }
    }
    .pagination .swiper-pagination {
      .swiper-pagination-bullet {
        border-color: #fff;
        &.active {
          background: #fff;
        }
      }
    }
  }
  .toggle {
    margin: 48px 0;
  }

  .slider.small {
    margin-top: 64px;
  }
}
