@import "./src/style/mixins";

.parameter-slide{
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  .image {
    width: 60px;
    height: 60px;
    img {border-radius: 4px;}
  }

  &.active {
    .check {
      z-index: 2;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }
  }
}

@include break-point(mobile){
  .parameter-slide {
    width: 42px;
    height: 42px;
    .image {
      width: 42px;
      height: 42px;
    }
    &.active {
      .check {
        transform: translate(50%, -50%);
      }
    }
  }
}