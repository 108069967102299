@import "../../../style/mixins";

.text-block {
  max-width: 800px;

  .title {
    @include title-h3;
    span {
      color: #00AAD2;
    }
  }

  h4.title {
    @include text-simple;
    font-family: "HyundaiMedium", "NotoSansArabicMedium", "Arial", "sans-serif";
    font-weight: 500;
  }

  .subtitle {
    @include text-simple;
    margin-top: 12px;
    max-width: 760px;
  }
}

@include break-point(mobile) {

}
