@import "../../style/mixins";

.fs-tiles {
  margin-top: 48px;

  .swiper-pagination {
    margin-top: 20px;
    display: none;
    bottom: 0;

    &-bullet {
      border: 1px solid #002C5E;

      &-active {
        background: #002C5E;
      }
    }
  }

  .swiper-slide {
    .cover {
      .dark-cover {
        z-index: 49;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000, rgba(0,0,0,0));
        opacity: 0.25;
      }

      .label-tiles {
        position: absolute;
        top: 10px;
        left: 10px;
        @include title-h1;
        font-weight: 500;
        color: #fff;
        z-index: 50;
        text-transform: uppercase;
      }
    }

    .title {
      margin-top: 24px;
      @include text-subtitle;
    }
  }
}

@include break-point(mobile) {

  .fs-tiles {

    .swiper-container {

      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }

    .swiper-pagination {
      display: block;
    }

    .swiper-slide {
      min-height: 340px;
      background: #fff;
      height: 100%;

      .cover {
        .label-tiles {
          font-size: 20px;
        }}

      .image {
        max-height: 100%;
      }

      .title {
        margin-top: 0;
        padding: 24px 16px;
        font-weight: 600;
      }
    }
  }
}