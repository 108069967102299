@import "../../../../style/mixins";

.picture-slide {

  .counter {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
  .image {
    span {
      width: 100%;
    }
  }
  &.white-counter {
    .counter {
      background: #fff;
      color: #000;
    }
  }

}


@include break-point(mobile) {
  .picture-slide {
    
    .counter {
      display: none;
    }
  }
}
